import { GridProps } from "@/types/components/grid/grid";

const Row = ({ children, className = '' }: GridProps) => {
    return (
        <div className={`row flex flex-wrap -mx-3 ${className}`}>
            {children}
        </div>
    );
}

export default Row;